import { gql } from '@apollo/client';
import client from '../apollo.';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../constants/toastNotifications';

export const loginUser = async (loginData) => {
  const { username, password } = loginData;
  try {
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation login($loginUserInput: LoginUserInput!) {
          login(loginUserInput: $loginUserInput) {
            access_token
            user {
              _count {
                Shipment
              }
              entityId
              firstName
              id
              entity {
                DBA
                EIN
                additionalNote
                address
                donation
                emailAddress
                id
                isBlocked
                name
                phoneNumber
                principalName
                rate
                rebateAddress
                rebateEmail
                since
                typeId
                userId
                website
                internationalRate
                policyDocument
              }
              location {
                _count {
                  shipment
                }
                id
                stripeCustomerID
                defaultPaymentMethodID
                address {
                  address
                  id
                  nickName
                }
              }
              lastName
              termsAccepted
              locationId
              role
              phoneNumber
              emailAddress
              additionalNote
            }
            type
          }
        }
      `,
      variables: {
        loginUserInput: {
          password,
          username,
          loginUserType: 'ENTITY_MEMBER',
        },
      },
    });
    console.log('err', errors);
    if (errors) {
      throw new Error(errors[0]);
    }
    return { data, errors };
  } catch (error) {
    console.log('error', error);
    toast.error(
      error?.name == 'ApolloError'
        ? 'Network Error: Please check your internet connection!'
        : 'Login unsuccessful. Please check your credentials and try again',
      { duration: TOAST_TIME }
    );
    throw new Error(error);
  }
};

export const fetchCurrentUserQuery = async () => {
  const { data } = await client.query({
    query: gql`
      query fetchCurrentUser {
        currentUser {
          _count {
            Shipment
          }
          entityId
          firstName
          id
          entity {
            name
            id
            rate
            internationalRate
            type {
              name
              id
            }
          }
          location {
            _count {
              shipment
            }
            id
            stripeCustomerID
            defaultPaymentMethodID
            address {
              address
              id
              nickName
            }
          }
          lastName
          locationId
          role
          termsAccepted
          phoneNumber
          emailAddress
          additionalNote
        }
      }
    `,
  });
  return data;
};

export const forgetPasswordMutation = async (forgetPasswordData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation ForgetPassword($email: String!) {
        forgetPassword(input: { email: $email })
      }
    `,
    variables: { email: forgetPasswordData.email },
  });

  return { data, errors };
};

export const resetPasswordMutation = async (resetPasswordData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
      }
    `,
    variables: {
      input: {
        token: resetPasswordData.verificationCode,
        email: resetPasswordData.email,
        newPassword: resetPasswordData.newPassword,
        confirmNewPassword: resetPasswordData.confirmPassword,
      },
    },
  });

  return { data, errors };
};
