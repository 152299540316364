import React from 'react';

import { plusGray } from '../../../assets';
import Breadcrumbs from '../BreadCrumbs';
import CustomButton from '../CustomButton'; // importing Custom Button for using in this componenet
import CustomContainer from '../CustomContainer'; // importing Custom Container for using in this componenet
import { useClientPanelHeader } from './useClientsPanelHeader';

// Note  ==> (This componenet is a reusable component which is being used in different pages header where we have routes history information and
// a button so from here we can send more props like routes history , its currently under development)

// Note ==>  right now its currently under development so no styling or other props are passed in this component
const ClientsPanelHeader = ({ hideReportButton }) => {
  const { reportShipmentHandler } = useClientPanelHeader();

  return (
    <CustomContainer className=' flex flex-col sm:flex-row justify-between items-start sm:items-center my-[24px]   sm:my-[30px] md:my-[36px] lg:my-[42px] '>
      <Breadcrumbs />
      <CustomButton
        onClick={() => reportShipmentHandler()}
        icon={plusGray}
        text='Report Shipment'
        className={`${
          hideReportButton && 'hidden'
        } bg-foundation-white px-[16px]  rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
      />
    </CustomContainer>
  );
};

export default ClientsPanelHeader;
