// Import React and other necessary libraries...
import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { SHIP_FROM } from '../../../constants/columns';
import DeleteModal from '../../modals/delete-modal';
import { ShipFromActionMenu } from '../components';
import { useShipFromList } from './useShipFromList';
import { AddressStaffHeader } from '../../components';
import { PATHS } from '../../../constants/paths';

const ShipFromList = () => {
  const {
    data,
    actionMenuRef,
    showDeleteModal,
    shipFromActionClickedId,
    closeDeleteModal,
    deleteAddressHandler,
    handleActionClick,
    toggleActionMenu,
    shipmFromActionClickedData,
    openDeleteModal,
    removeAddressLoading,
  } = useShipFromList();

  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          deleteHandler={() => deleteAddressHandler(shipFromActionClickedId)}
          modalHeading='Delete Ship Address?'
          modalPara='Are you sure you want to delete this Ship Address? This action cannot be undone.'
          closeDeleteModal={closeDeleteModal}
          deleteLoading={removeAddressLoading}
        />
      ) : null}

      <CustomContainer>
        <ClientsPanelHeader />

        <div className='bg-[#fff] border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid border-[#EAECF0]'>
          <AddressStaffHeader
            heading='Ship From  Address(es)'
            butonText='Add Ship From'
            link={PATHS.ADD_SHIP_FROM_ADDRESS}
          />
          <CustomTable
            header={SHIP_FROM}
            rows={data}
            actionMenuRef={actionMenuRef}
            {...{ handleActionClick, toggleActionMenu }}
            menu={
              <ShipFromActionMenu
                data={shipmFromActionClickedData}
                openDeleteModal={openDeleteModal}
              />
            }
          />
        </div>
      </CustomContainer>
    </>
  );
};

export default ShipFromList;
