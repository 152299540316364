import React from 'react';
import { useBilling } from './useBillings';
import { BILLINGS } from '../../constants/columns';
import CustomContainer from '../../common/custom/CustomContainer';
import CustomTable from '../../common/custom/CustomTable/custom-table.component';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../common/custom';
import FilterBillingModal from './filterBilling';
import { crossCircle, filterIcon } from '../../assets';
import DownloadCSVButton from '../../common/custom/download-csv';

const Biillings = () => {
  const {
    data,
    loading,
    showFilterModal,
    handleOpenFilterModal,
    handleCloseFilterModal,
    handleFilterSubmit,
    handleFilterReset,
    handleSubmit,
    register,
    errors,
    setValue,
    isFilterCleared,
    setIsFilterCleared,
  } = useBilling();
  return (
    // <CustomContainer>
    //   <ClientsPanelHeader hideReportButton />
    //   <div className='border-[1px] border-[#EAECF0] border-[color:var(--Gray-200,#EAECF0)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid   '>
    //     <CustomContainer className='p-6 min-h-[75px] flex justify-center items-center'>
    //       <CustomHeading className='p-1 lg:p-0 text-[#141414] font-inter text-small-heading font-[600] max-w-[1152px] w-full h-full  '>
    //         Billing
    //       </CustomHeading>
    //     </CustomContainer>
    //     <CustomContainer>
    //       <div className='overflow-x-auto overflow-y-auto'>
    //         <div className='min-w-[1182px]  max-h-[547px]'>
    //           <CustomTable
    //             header={BILLINGS}
    //             rows={data}
    //           />
    //         </div>
    //       </div>
    //     </CustomContainer>
    //   </div>
    // </CustomContainer>

    <CustomContainer>
      {showFilterModal && (
        <FilterBillingModal
          closeFilterModal={handleCloseFilterModal}
          onReset={handleFilterReset}
          errors={errors}
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          onSubmit={handleFilterSubmit}
          setIsFilterCleared={setIsFilterCleared}
        />
      )}

      <ClientsPanelHeader />
      <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
        <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
          <div className='flex justify-between items-center flex-row w-full'>
            <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
              Billings
            </CustomHeading>
            <div className='flex justify-between gap-4 w-[20%]'>
              {data?.length > 0 && (
                <DownloadCSVButton data={data} filename={'account-billings'} />
              )}

              {!loading &&
                (isFilterCleared ? (
                  data?.length > 0 && (
                    <CustomButton
                      onClick={handleOpenFilterModal}
                      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                      text='Filter'
                      icon={filterIcon}
                    />
                  )
                ) : (
                  <CustomButton
                    onClick={() => setIsFilterCleared(true)}
                    className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] min-w-[140px] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                    text='Clear Filters'
                    icon={crossCircle}
                  />
                ))}
            </div>{' '}
          </div>
        </CustomContainer>
        <div className=' overflow-x-auto'>
          {data?.length ? (
            <CustomTable header={BILLINGS} rows={data} table='billing' />
          ) : loading ? (
            <CustomEmptyDataComponent
              heading='Loading data...'
              loading={true}
            ></CustomEmptyDataComponent>
          ) : (
            <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
          )}{' '}
        </div>
      </div>
    </CustomContainer>
  );
};

export default Biillings;
