import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';

import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { STAFF } from '../../../constants/columns';
import { PATHS } from '../../../constants/paths';
import { AddressStaffHeader } from '../../components';
import { StaffListActionMenu } from '../components';
import { useStaffList } from './useStaff';
import { DeleteModal } from '../../modals';

const StaffList = () => {
  const {
    data,
    toggleActionMenu,
    handleActionClick,
    actionMenuRef,
    openDeleteModal,
    staffActionClickedData,
    showDeleteModal,
    closeDeleteModal,
    staffActionClickedId,
    handleDeleteStaff,
    deleteStaffHandler,
    editStaffHandler,
    deleteStaffLoading,
  } = useStaffList();

  return (
    <CustomContainer>
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Shipment?'
          modalPara='Are you sure you want to delete this Shipment? This action cannot be undone.'
          deleteHandler={() => deleteStaffHandler(staffActionClickedId)}
          closeDeleteModal={closeDeleteModal}
          deleteLoading={deleteStaffLoading}
        />
      ) : null}

      <ClientsPanelHeader />
      <div className='bg-[#fff] border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid border-[#EAECF0]'>
        <AddressStaffHeader
          heading='Staff'
          butonText='Add Member'
          link={PATHS.STAFF_ADD_STAFF_MEMBER}
        />

        <CustomTable
          header={STAFF}
          rows={data}
          {...{ handleActionClick, toggleActionMenu }}
          actionMenuRef={actionMenuRef}
          menu={
            <StaffListActionMenu
              deleteStaffHandler={deleteStaffHandler}
              data={staffActionClickedData}
              openDeleteModal={openDeleteModal}
            />
          }
        />
      </div>
    </CustomContainer>
  );
};

export default StaffList;
