import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import { crossCircle, pdfIcon } from '../../../assets';
import { CustomIcon } from '../../customAssets';
import PreviewFullImageModal from '../Modals/PreviewFullImage/modal';
import useFilePreview from './use-file-preview.hook';
import { FILE_SIZE_B_TO_MB, flattenFilesArray } from '../../../helpers/helpers';

function FilePreview({ files, handleCancel }) {
  const { open, handleFullScreenPreview, handleClose } = useFilePreview();

  const flattenedData = flattenFilesArray(files);

  return (
    // <div>
    //   <PreviewFullImageModal
    //     title="Veiw Document"
    //     show={open}
    //     file={file}
    //     onClose={handleClose}
    //   />
    //   <div class="flex max-h-[126px] flex-col items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
    //     <div class="flex flex-col relative">
    //       {file && file.location?.split(".").pop() === "pdf" ? (
    //         <div className="flex items-center gap-4 p-2 rounded-lg bg-[#efefef]">
    //           <div className="flex gap-4">
    //             <img
    //               src={pdfIcon}
    //               alt="file"
    //               className="h-10 hover:cursor-pointer"
    //             />
    //             <div className="">
    //               <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6">
    //                 {file?.originalname}
    //               </div>
    //               <div className="text-[#747474] text-xs not-italic font-normal leading-[normal]">
    //                 {FILE_SIZE_B_TO_MB(file.size)}MB
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       ) : (
    //         <img
    //           src={file?.location}
    //           alt="file"
    //           className="h-[80px] hover:cursor-pointer"
    //           onClick={handleFullScreenPreview}
    //         />
    //       )}
    //       <Tooltip className="" content="Cancel">
    //         <div
    //           className="absolute right-[-8px] top-[-12px]"
    //           onClick={handleCancel}
    //         >
    //           <CustomIcon icon={crossCircle} className="w-6 h-6" />
    //         </div>
    //       </Tooltip>
    //     </div>
    //   </div>
    // </div>
    <div>
      <PreviewFullImageModal
        title='Veiw Document'
        show={open}
        file={flattenedData}
        onClose={handleClose}
      />
      <div class='flex max-h-[126px] flex-col items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
        <div class='flex gap-4 relative'>
          {flattenedData?.length > 0 &&
            flattenedData.map((file, index) => (
              <div key={index} className='relative inline-block m-2'>
                {file?.location?.toLowerCase()?.endsWith('.pdf') ? (
                  <div
                    className='h-[80px] w-[80px] flex justify-center items-center hover:cursor-pointer'
                    // onClick={() => handleDownload(file.location)}
                  >
                    <img
                      src={pdfIcon} // Display PDF icon
                      alt='pdf file'
                      className='h-[40px]' // Adjust size as needed
                    />
                  </div>
                ) : (
                  <img
                    key={index}
                    src={file?.location}
                    alt='file'
                    className='h-[80px] hover:cursor-pointer'
                    // onClick={handleFullScreenPreview}
                  />
                )}
                <Tooltip className='' content='Cancel'>
                  <div
                    key={index}
                    className='absolute right-[-8px] top-[-12px]'
                    onClick={() => handleCancel(index)}
                  >
                    <CustomIcon
                      icon={crossCircle}
                      className='w-6 h-6 cursor-pointer'
                    />
                  </div>
                </Tooltip>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default FilePreview;
