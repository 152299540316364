import React from 'react';

import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import { NoShipmentsComponent } from '../components';
import AllShipments from '../components/allShipments';
import { useShipments } from './useShipments';

const Shipments = () => {
  const { data } = useShipments();

  if (data?.length === 0) {
    return (
      <NoShipmentsComponent>
        <ClientsPanelHeader />
      </NoShipmentsComponent>
    );
  }
  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <AllShipments data={data} />
    </CustomContainer>
  );
};

export default Shipments;
