import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/paths";
import { TOAST_TIME } from "../../../constants/toastNotifications";
import { resetReportShipmentFormAction } from "../../../store/slices/formsSlice";

export const useClientPanelHeader = () => {
  const { payments } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportShipmentHandler = () => {
    dispatch(resetReportShipmentFormAction());
    if (Array.isArray(payments?.payments) && payments?.payments.length > 0) {
      navigate(PATHS.REPORTS_SHIPMENTS);
    } else {
      toast.dismiss();
      toast.error("Please add a payment method", { duration: TOAST_TIME });
    }
  };

  return { reportShipmentHandler };
};
