import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomIcon } from '../../../common/customAssets';
import { paymentMasterIcon, paymentVisaIcon } from '../../../assets';
import {
  fetchCurrentUserQuery,
  getListPaymentMethodsQuery,
  updateDefaultLocation,
} from '../../../api';
import { storeAllPaymentsAction } from '../../../store/slices/payments';
import useApi from '../../../hook/useApi';
import toast from 'react-hot-toast';
import {} from 'react-redux';
import { CustomButton } from '../../../common/custom';
import { ALL_UPPER_CASE } from '../../../helpers/helpers';
import useOutsideClick from '../../../hook/useOutsideClick';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { removePaymentMethodMutation } from '../../../api/services/paymentMethods.service';

export const usePaymentMethodList = () => {
  const selectedPayment = useRef(null);
  const [isWarningDialog, setIsWarningDialog] = useState(true);
  const [warningAccepted, setWarningAccepted] = useState(false);
  const user = JSON.parse(localStorage.getItem('login-user'));

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [paymentActionClickedData, setPaymentActionClickedData] =
    useState(null);
  const [toggleCardActionMenu, setToggleCardActionMenu] = useState(null);

  const actionMenuRef = useRef(null);
  const actionCardMenuRef = useRef(null);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  useOutsideClick(actionCardMenuRef, () => setToggleCardActionMenu(false));

  const [getListPaymentMethods] = useApi(
    getListPaymentMethodsQuery,
    storeAllPaymentsAction
  );
  useEffect(() => {
    getListPaymentMethods(user.location.stripeCustomerID);
  }, []);

  const { payments } = useSelector((state) => state);

  useEffect(() => {
    refetchCurrentUser();
  }, []);

  useEffect(() => {
    updateMutation();
  }, [warningAccepted]);

  const renderCardTypeIcon = (cardType) => {
    if (cardType === 'visa') {
      return <CustomIcon icon={paymentVisaIcon} />;
    }
    if (cardType === 'mastercard') {
      return <CustomIcon icon={paymentMasterIcon} />;
    }
  };

  useEffect(() => {
    const modifiedData = payments?.payments?.map((paymentMethod) => {
      return {
        ...paymentMethod,
        paymentMethod: `XXXX-${paymentMethod.card.last4}`,
        type: (
          <div className='bg-[#E6EAFF] text-[#314EEB] rounded-[50px] font-inter font-[500] text-sm  w-[56px] min-h-[28px] flex justify-center items-center'>
            {ALL_UPPER_CASE(paymentMethod && paymentMethod.type)}
          </div>
        ),
        provider: (
          <div className='flex items-center gap-[8px]'>
            {renderCardTypeIcon(paymentMethod.card.brand)}
          </div>
        ),
        expiration: `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`,
        default:
          user.location.defaultPaymentMethodID === paymentMethod.id ? (
            <CustomButton
              variant='text'
              className='flex items-center bg-[#fff2e9] text-[#E55F00] text-sm not-italic font-medium leading-[normal] px-3 py-2 rounded-[50px]'
              text='Default'
            />
          ) : (
            <CustomButton
              variant='outlined'
              size='sm'
              text='Set Primary'
              className='flex items-center bg-[#fff2e9] text-[#E55F00] text-sm not-italic font-medium leading-[normal] px-3 py-2 rounded-[50px]'
            />
          ),
      };
    });
    setPaymentMethods(modifiedData);
  }, [warningAccepted]);

  const refetchCurrentUser = async () => {
    const result = await fetchCurrentUserQuery();
    if (result != null) {
      localStorage.setItem('login-user', JSON.stringify(result.currentUser));
    }
  };

  async function updateDefaultPaymentIDMutationRequest(payment) {
    selectedPayment.current = payment;
    if (payment.type === 'card') {
      setIsWarningDialog(true);
    } else {
      updateMutation();
    }
  }

  async function updateMutation() {
    if (user.locationId && selectedPayment.current != null) {
      toast.dismiss();
      toast.loading('Please wait. Data is updating...');
      var result = await updateDefaultLocation(
        parseInt(user.locationId),
        selectedPayment.current.id
      );
      if (result) {
        toast.dismiss();
        toast.success('Payment updated successfully');
        refetchCurrentUser(result);
      } else {
        toast.error('Something Went Wrong, Please try again');
      }
    }
  }

  const handleActionClick = async (e, data) => {
    if (e) e.stopPropagation();
    setPaymentActionClickedData(data);
    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
  };

  const handleDeleteCard = async () => {
    try {
      const response = await removePaymentMethodMutation(
        paymentActionClickedData.id
      );
      if (response?.data) {
        setToggleCardActionMenu(null);
        setOpenDeleteModal(false);

        const updatedPaymentMethods = paymentMethods.filter(
          (method) => method.id !== paymentActionClickedData?.id
        );
        setPaymentMethods(updatedPaymentMethods);
        toast.success('Payment Method deleted successfully', {
          duration: TOAST_TIME,
        });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    } catch (error) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    } finally {
      setToggleCardActionMenu(null);
      setOpenDeleteModal(false);
    }
  };

  return {
    user,
    paymentMethods,
    renderCardTypeIcon,
    updateDefaultPaymentIDMutationRequest,
    handleActionClick,
    paymentActionClickedData,
    toggleActionMenu,
    toggleCardActionMenu,
    openDeleteModal,
    setOpenDeleteModal,
    actionMenuRef,
    handleDeleteCard,
  };
};
