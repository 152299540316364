import { gql } from '@apollo/client';
import client from '../apollo.';
import _ from 'lodash';
export const reportShipmentMutation = async (
  reportShipmentData

  // additionalNote,
  // carrierId, // ==> carrier id
  // deliveryDocuments,
  // documents,
  // deliveryDate,
  // locationId,
  // otherCarrier, // ==> set it empty " "
  // pickUpDate,
  // to, //
  // {
  //   name: name, ==> it is destination
  //   reference: reference, ==> reference no
  //   street: street, ==> refernence number
  //   unitNo: unitNo, ===> unit number
  //   city: city, ====> city
  //   zipCode: zipCode,  ===> zipcode
  //   state: state,  ===> state
  //   country: 'USA',
  // }

  // totalValue, // declaredValue
  // from, // ==> selectted location id
  // entityId, // ==> uid
  // merchType, // merc id
  // hasExpensiveItem // true or false with radio button
) => {
  reportShipmentData.document = JSON.stringify(
    _.flatten(reportShipmentData.document)
  );
  console.log('reportShipmentData', reportShipmentData);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation($createShipmentInput: ShipmentCreateInput!) {
        createShipment(createShipmentInput: $createShipmentInput) {
          id
          from {
            nickName
          }

          location {
            name
          }

          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          pickUpDate
          isTrial
          subscribers
        }
      }
    `,
    variables: {
      createShipmentInput: {
        subscribers: reportShipmentData.isTrial?.toString(),
        additionalNote: reportShipmentData.additionalNote,
        carrier: {
          connect: {
            // id: carrierId,
            id: parseInt(reportShipmentData.carrier),
          },
        },
        // deliveryDocuments,
        deliveryDocuments: '',
        // documents,
        documents: reportShipmentData.document,
        deliveryDate: reportShipmentData.deliveryDate,
        location: {
          connect: {
            id: reportShipmentData.locationId,
          },
        },
        entityMember: {
          connect: {
            id: reportShipmentData.entityId, //uid here
          },
        },
        from: {
          connect: {
            // id: from,
            id: reportShipmentData.from,
          },
        },
        shipmentType: {
          connect: {
            // id: parseInt(merchType),
            id: 2, // todo.. hardcoded ?
          },
        },
        // otherCarrier,
        otherCarrier: '',
        pickUpDate: reportShipmentData.pickupDate,
        to: JSON.stringify({
          name: reportShipmentData.destination,
          reference: reportShipmentData.reference,
          street: reportShipmentData.streetAddress,
          unitNo: parseInt(reportShipmentData.unitNumber),
          city: reportShipmentData.city,
          zipCode: reportShipmentData.zipCode,
          state: reportShipmentData.state,
          country: reportShipmentData.country,
        }),
        totalValue: parseInt(reportShipmentData.declaredValue),
        // hasExpensiveItem,
        hasExpensiveItem: false,
      },
    },
  });
  return { data, errors };
};

export const updateShipmentMutation = async (updateDatat) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateShipment(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        updateShipment(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: updateDatat.shipmentId,
      },
      updateShipmentInput: {
        from: {
          connect: {
            // id: from,
            id: updateDatat.fromLocationId,
          },
        },
        pickUpDate: {
          set: updateDatat.pickUpDate,
        },
        to: {
          set: updateDatat.to,
        },
        totalValue: {
          set: updateDatat.declaredValue,
        },
        deliveryDate: {
          set: updateDatat.deliveryDate,
        },
        deliveryDocuments: {
          set: '',
        },
        otherCarrier: {
          set: '',
        },
        documents: {
          set: updateDatat.documents,
        },
        carrier: {
          connect: {
            id: parseInt(updateDatat.carrier),
          },
        },
        additionalNote: {
          set: updateDatat.additionalNote,
        },
        shipmentType: {
          connect: {
            id: parseInt(updateDatat.merchandiseType),
          },
        },
      },
    },
  });
  return { data, errors };
};

export const deliverShipmentMutation = async ({ shipmentId, documents }) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation deliverShipment(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        deliverShipment(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: shipmentId,
      },
      updateShipmentInput: {
        receipt: {
          set: documents,
        },
        isDelivered: {
          set: true,
        },
      },
    },
  });
  return { data, errors };
};

export const cancelShipmentMutation = async (shipmentId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CancelShipment($cancelShipmentId: Int!) {
        cancelShipment(id: $cancelShipmentId) {
          id
        }
      }
    `,
    variables: {
      cancelShipmentId: shipmentId,
    },
  });
  return { data, errors };
};

export const getAllShipmentsFromLocationQuery = async (locationId) => {
  const { data, errors } = await client.query({
    query: gql`
      query Shipments($locationId: Float!) {
        allShipmentFromLocation(locationId: $locationId) {
          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          id
          isTrial
          subscribers
          premium
          location {
            name
            entity {
              id
              rate
              name
              internationalRate
            }
          }
          claim {
            id
            createdAt
          }
          deliveryDocuments
          shipmentType {
            id
            name
          }
          documents
          isDelivered
          locationId
          paid
          pickUpDate
          reason
          status
          to
          totalValue
          verifiedById
          carrierId
          entityMember {
            lastName
            id
            firstName
          }
          carrier {
            name
          }
          from {
            nickName
          }
        }
      }
    `,
    variables: {
      locationId: locationId,
    },
  });
  return { data, errors };
};

export const getShipmentQuery = async (id) => {
  const { data, errors } = await client.query({
    query: gql`
      query Shipment($shipmentId: Int!) {
        shipment(id: $shipmentId) {
          additionalNote
          createdAt
          carrierId
          deliveryDate
          shipmentType {
            id
            name
          }
          documents
          deliveryDocuments
          from {
            nickName
            id
            address
          }
          id
          otherCarrier
          pickUpDate
          to
          totalValue
          locationId
          isTrial
          subscribers
          premium
        }
      }
    `,
    variables: {
      shipmentId: id,
    },
  });
  return { data, errors };
};

export const allShipmentFromEntityQuery = async (entityID) => {
  const { data, errors } = await client.query({
    query: gql`
      query allShipmentFromEntity($entityId: Float!, $entityId2: Int!) {
        allShipmentFromEntity(entityId: $entityId) {
          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          id
          isTrial
          subscribers
          premium
          deliveryDocuments
          shipmentType {
            id
            name
          }
          location {
            name
            entity {
              rate
              name
            }
          }
          documents
          isDelivered
          locationId
          paid
          pickUpDate
          reason
          status
          paymentIntentId
          to
          concludedOn
          totalValue
          verifiedById
          carrierId
          isTrial
          subscribers
          entityMember {
            lastName
            id
            firstName
          }
          carrier {
            name
          }
          from {
            nickName
          }
        }
        entity(id: $entityId2) {
          name
        }
      }
    `,
    variables: {
      entityId: entityID,
      entityId2: parseInt(entityID.toString()),
    },
  });
  return { data, errors };
};

export const checkTrialShipmentQuery = async (entityID) => {
  const { data, errors } = await client.query({
    query: gql`
      query checkTrialQuery($entityId: Float!) {
        checkTrialQuery(entityId: $entityId) {
          isFirst
        }
      }
    `,
    variables: {
      entityId: entityID,
    },
  });
  return { data, errors };
};

// The remaining functions remain the same as the TypeScript code.

// addressId
// createdAt
// carrierId
// deliveryDate
// receipt
// shipmentType {
//   id
//   name
// }
// claim {
//   id
//   createdAt
// }
// carrier {
//   name
// }
// documents
// deliveryDocuments
// from {
//   nickName
//   id
//   address
// }
// id
// otherCarrier
// pickUpDate
// to
// totalValue
// locationId

// mutation UpdateEntityMember {
//   updateEntityMember(
//       updateEntityMemberInput: null
//       updateEntityMemberUniqueInput: null
//   ) {
//       additionalNote
//       createdAt
//       emailAddress
//       entityId
//       firstName
//       id
//       lastModified
//       lastName
//       locationId
//       password
//       phoneNumber
//       resetToken
//       role
//       termsAccepted
//       userId
//   }
// }
